* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

html,
body {
  text-rendering: optimizeSpeed;

}

body {
  color: var(--gray800);
  font: 400 px_to_rem(14px) arial;
  text-align: left;
  line-height: 1.4;
  overflow-x: hidden;
  background: #EEF2F6;
  max-width: 575px;
  margin: 0 auto;

  &>svg {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
  }

  &.show {
    overflow: hidden;
  }
}

.body_mobile {
  max-width: 540px;
  margin: 0 auto;
  overflow-x: hidden;
}

body.show_main_menu,
body.hidden {
  overflow: hidden;
  height: 100vh;
  position: relative;
}

.container {
  max-width: 540px;
  margin: 0 auto;
  padding: 0 12px;
}

.hide {
  display: none !important;
}

a:focus {
  outline: 1 !important;
}

.box_category {
  margin-bottom: 20px;
}

.banner_mobile_300x250 {
  background: #f4f4f4;
  font-size: 0;
}

a {
  color: #132639;
  text-decoration: none;
  outline: 1;
}

a:hover {
  color: var(--blue);
}

.btn {
  font-size: 1rem;
  line-height: 20.8px;
  color: #999;
  background: #eeeeee;
  padding: 8px 25px;
  border: none;
  cursor: pointer;
  display: inline-block;
  border-radius: 4px;

  &_dvc {
    background: var(--pink);
    color: #fff;
  }
}

.section {
  position: relative;
  clear: both;
}

.section_padding {
  padding: 0 12px;
  clear: both;

  .search-form {
    padding: 0
  }
}

.bg-white {
  background: #fff;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
}

.radius-4 {
  .thumb_img {
    border-radius: 4px;
  }
}

.flex {
  display: inherit;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.width_common {
  float: left;
  width: 100%;
}

.center {
  text-align: center;
}

.navigation_scroll {
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#to_top {
  opacity: .8 !important;
  background: #999;
  display: none;
  border: medium none;
  bottom: 50px;
  cursor: pointer;
  text-align: center;
  height: 30px;
  line-height: 28px;
  outline: medium none;
  padding: 0;
  position: fixed;
  right: 20px;
  width: 30px;
  z-index: 89;

  .icon-ns {
    margin: 0;
    color: #fff;
  }
}

img {
  border: 0;
  font-size: 0;
  line-height: 0;
  max-width: 100%;
  vertical-align: middle;
}

ul,
li {
  list-style-type: none;
}

.form-control {
  appearance: none;
}

input,
textarea {
  font-family: arial;
  font-size: 11px;
  border: none;
  background: none;
  outline: 0;
}

input::-webkit-input-placeholder {
  color: #999999;
}

input::-moz-input-placeholder {
  color: #999999;
}

input::-ms-input-placeholder {
  color: #999999;
}

input::-o-input-placeholder {
  color: #999999;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.clearfix {
  clear: both;
}

.clearfix::before,
.clearfix::after {
  display: table;
  content: "";
}

.left {
  float: left;
}

.right {
  float: right;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}

.hidden {
  display: none !important
}

.hidden320 {
  font-size: 0;
}

html {
  -webkit-text-size-adjust: none;
}

.fill-gradient {
  position: relative;

}

svg.ic,
.icon-svg,
.icon-ns {
  height: 15px;
  width: 15px;
  vertical-align: middle;
  fill: currentColor;
}


.comment-count,
.count_comment {
  font: 400 px_to_rem(14px) arial;
  margin-left: 3px;
  color: #56738F;
}

.count_cmt {
  span {
    font: 400 px_to_rem(14px) arial;
    color: #56738F;
  }

  svg {
    color: #2B9DEE
  }
}

.icon_commend,
.count_comment_total {
  white-space: nowrap;

  svg {
    color: #2B9DEE
  }
}

.ns-play {
  position: absolute;
  bottom: 5px;
  left: 5px;
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin: 0;
}

.user-va-checked {
  font-size: 12px;
  color: #514E4E;
  font-family: Arial;
  display: inline-block;

  a {
    color: #514E4E;
    font: 400 14px Arial;
  }

  svg {
    vertical-align: text-bottom;
    width: 14px !important;
    height: 14px !important;
  }

  &.disabled {
    svg {
      color: #AAB1B5 !important
    }
  }
}

.block_share {
  .user-va-checked {
    a {
      width: auto;
    }

    .icon-ns {
      position: static;
    }
  }
}

.no_wrap {
  white-space: nowrap;
}

.txt_666 {
  color: #666;
}

.txt_10 {
  font-size: 10px;
}

.txt_11 {
  font-size: 11px;
}

.txt_14 {
  font-size: 14px;
}

.txt_16 {
  font-size: 16px;
}

.txt_vne {
  color: #9f224e;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb16 {
  margin-bottom: 16px;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb24 {
  margin-bottom: 24px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt12 {
  margin-top: 12px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

/*.grecaptcha-badge {
    display: none !important;
}*/
.error {
  color: red;
  font-size: 12px;
}

.blue {
  color: #0963C8;
}

.bg_gray {
  background: #f8f8f6;

  &.fs {
    background: #fff !important;

    .text_ads {
      width: 300px;
      display: block;
      margin: 0 auto 15px;
      font: 400 11px arial;
      color: #a7a7a7;
      text-align: center;
      position: relative;

      &:before {
        width: 300px;
        height: 1px;
        background: #eee;
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        right: 0;
      }

      .txt_qc {
        position: relative;
        display: inline-block;
        background: #fff;
        padding: 0 7px;
      }
    }
  }
}

.btn_ns {
  font: 400 px_to_rem(16px) arial;
  background: var(--blue);
  border: 0;
  color: #fff;
  padding: 8px 15px;
  border-radius: 4px;
}

.fs {
  font-size: 0
}

.thumb_big {
  position: relative;
}

.thumb_art {
  position: relative;
  float: left;
}

.thumb_img {
  display: block;
  overflow: hidden;
  height: 1px;
  position: relative;
  width: 100%;
}

.thumb_img.thumb_video,
.embed-container {
  padding-bottom: 56.25%;
}

.thumb_img.thumb_5x3 {
  padding-bottom: 60%;
}

.thumb_img.thumb_5x5,
.thumb_1x1 {
  padding-bottom: 100%;
}

.thumb_img,
.thumb_video,
.thumb_16x9 {

  img,
  video,
  iframe {
    -o-object-position: top;
    object-position: top;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.thumb_video+.title_news {
  margin-top: 10px;
  font-size: px_to_rem(16px);
  text-transform: uppercase;
  line-height: 1.4;

  a {
    color: var(--blue)
  }
}

.video-js .vjs-play-progress {
  background-color: #0064b2 !important;
}

/*loadding-new and live plus*/
.loadding-new {
  text-align: center;
  margin-bottom: 28px;

  .sk-child {
    margin: 0 2px;
    width: 11px;
    height: 11px;
    background-color: var(--blue);
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
    animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  }

  .sk-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .sk-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

@-webkit-keyframes sk-three-bounce {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-three-bounce {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/**END RESET CSS**/
.ic-live {
  margin-right: 6px;
  font-family: arial;
  font-size: 0;
  height: 15px;
  line-height: 15px;
  display: inline-block;
  vertical-align: middle;
  color: var(--blue);

  &:before {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: block;
    position: relative;
    width: 10px;
    height: 10px;
    content: '';
    background: var(--blue);
    box-shadow: 0 0 0 rgba(23, 143, 214, 0.4);
    margin-right: 5px;
    vertical-align: initial;
    -webkit-animation: live-pulse 1s infinite;
    -moz-animation: live-pulse 1s infinite;
    -o-animation: live-pulse 1s infinite;
    animation: live-pulse 1s infinite;
    float: left;
    margin-top: 2px;
  }

  &:after {
    content: 'Live';
    font-style: normal;
    color: var(--blue);
    font-size: 11px;
  }

  &.not-start {
    &:before {
      background: #757575;
      box-shadow: 0 0 0 rgba(117, 117, 117, 0.4);
      -webkit-animation: live-pulse-2 1s infinite;
      -moz-animation: live-pulse-2 1s infinite;
      -o-animation: live-pulse-2 1s infinite;
      animation: live-pulse-2 1s infinite;
    }

    &:after {
      content: 'Chưa bắt đầu';
      color: #222;
    }
  }

  &.the-end {
    &:before {
      background: #757575;
      box-shadow: 0 0 0 rgba(117, 117, 117, 0.4);
      -webkit-animation: live-pulse-2 1s infinite;
      -moz-animation: live-pulse-2 1s infinite;
      -o-animation: live-pulse-2 1s infinite;
      animation: live-pulse-2 1s infinite;

    }

    &:after {
      content: 'Đã kết thúc' !important;
      color: #757575;
    }
  }

  &.ic-live-title {
    font-family: "Merriweather", serif;
    font-style: normal;
    color: #9F224E !important;
    margin-right: 5px;
    font-weight: 700;

    &:after {
      content: '';
    }

    &:before {
      top: 7px;
    }
  }
}

@-webkit-keyframes live-pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(23, 143, 214, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(23, 143, 214, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(23, 143, 214, 0);
  }
}

@keyframes live-pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(23, 143, 214, 0.4);
    box-shadow: 0 0 0 0 rgba(23, 143, 214, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(23, 143, 214, 0);
    box-shadow: 0 0 0 10px rgba(23, 143, 214, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(23, 143, 214, 0);
    box-shadow: 0 0 0 0 rgba(23, 143, 214, 0);
  }
}

.line_8px {
  background: #eeeeee;
  height: 8px;
  width: 100%;
  float: left;
}

.line_4px {
  background: #eeeeee;
  height: 4px;
  width: 100%;
  float: left;
}

#pagination {
  margin-bottom: 28px;
  text-align: center;

  &.folder {
    a {
      width: 40px;
      height: 40px;
      line-height: 40px;
      display: inline-block;
      margin: 0 2px;
      color: #999;
      border: 1px solid #dfdfdf;
      font-size: px_to_rem(17px);
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;

      &:hover,
      &.active {
        background: var(--blue);
        color: #fff;
        border: 1px solid var(--blue);

        .icon-ns {
          color: #fff
        }
      }
    }

    .pagination_btn {
      line-height: 38px;

      .icon-ns {
        width: 12px;
        height: 12px;
        margin: 0;
        color: #999
      }
    }
  }
}



// .embed_video_new {
//   position: static !important;

//   .box_img_video {
//     position: relative;
//     padding-bottom: 56.25%;

//     img,
//     video,
//     iframe {
//       -o-object-position: top;
//       object-position: top;
//       -o-object-fit: cover;
//       object-fit: cover;
//       position: absolute;
//       top: 0;
//       bottom: 0;
//       left: 0;
//       right: 0;
//       width: 100% !important;
//       height: 100% !important;
//     }
//   }
// }

.icon_blockvideo {
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='25' height='30' viewBox='0 0 25 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath opacity='0.7' d='M24 13.2679C25.3333 14.0378 25.3333 15.9622 24 16.732L3 28.8564C1.66666 29.6262 -1.38408e-06 28.664 -1.31678e-06 27.1244L-2.56832e-07 2.87564C-1.89534e-07 1.33604 1.66667 0.373792 3 1.14359L24 13.2679Z' fill='white'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  position: absolute;
  left: 50% !important;
  top: 50% !important;
  margin: -15px 0 0 -15px;

  .img_icon {
    display: none !important
  }
}

.lazy {
  transform: translateX(0%) !important;
  left: 0 !important;
}

// .block_thumb_picture img.lazy {
//   height: 100%
// }