.search-form {
    padding: 0 12px;
    clear: both;

    .form-group {
        position: relative;
    }

    .form-button {
        position: absolute;
        display: inline-block;
        background: unset;
        border: none;
        width: 42px;
        height: 42px;
        right: 0;
        top: 0;

        svg {
            width: 16px;
            height: 16px;
            fill: #7A99B8;
        }
    }

    .form-control {
        flex-grow: 1;
        font-size: 16px;
        color: #1F3347;
        line-height: 150%;
        height: auto;
        border: 1px solid #D8E1EB;
        border-radius: 61px;
        padding: 0 42px 0 15px;
        width: 100%;
        height: 42px;

        &::placeholder {
            color: #56738F;
        }
    }
}

.title_box_category {
    position: relative;
    font: 500 1.25rem iCielDomaineText;
    background: #fff;
    padding: 12px;
    border-radius: 4px 4px 0px 0px;
    overflow: hidden;

    p {
        color: #56738F;
        font: 400 16px Arial;
    }

    &::before {
        content: "";
        background: linear-gradient(90deg, #0C99F4 0%, #57E5D5 100%);
        height: 4px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
}


.title_news {
    line-height: normal;

    p {
        font-size: 12px;
        color: #514E4E;
        font-family: Arial;
        display: inline-block;

        a {
            color: #514E4E;
            font: 400 14px Arial;
        }

        svg {
            vertical-align: text-bottom;
            width: 14px !important;
            height: 14px !important;
        }

        &.disabled {
            svg {
                color: #AAB1B5
            }
        }
    }
}

.xem_them {
    color: #56738F;
    text-align: center;
    font-size: 16px;
    display: block;
    padding: 13px 0;
    background: #fff;
    border-top: 1px solid #D8E1EB;

    &.no-border {
        border-top: 0;
        border-radius: 4px;
    }
}

.art_item {
    position: relative;
    margin-bottom: 12px;

    .thumb_art {
        position: relative;
        width: 128px;
        margin-right: 8px;
        overflow: hidden;

        .icon_thumb {
            position: absolute;
            background: rgba(24, 27, 29, 0.7);
            border-radius: 4px;
            width: 32px;
            height: 32px;
            line-height: 30px;
            text-align: center;
            bottom: 12px;
            left: 12px;
            color: #fff;
        }

        .timer {
            position: absolute;
            left: 4px;
            bottom: 4px;
            background: #132639;
            border-radius: 4px;
            color: #fff;
            line-height: 20px;
            padding: 0 5px;
        }

        .play {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 36px;
            height: 36px;
            line-height: 36px;
            margin-left: -18px;
            margin-top: -18px;
            text-align: center;

            svg {
                width: 32px;
                height: 32px;
                color: #fff;
                opacity: 0.7;
            }
        }

        &.thumb_big {
            width: 100%;
            float: none;
            margin-right: 0;

            .timer {
                left: 12px;
                bottom: 8px
            }
        }
    }

    .content {
        background: #fff;
        padding: 12px;
        border-radius: 0 0 4px 4px;

        .title_news {
            font: 500 px_to_rem(20px) var(--font-title);
            line-height: 1.4;

            svg {
                color: #2B9DEE;
                margin: 0 2px;
            }
        }

        .description {
            font: 400 px_to_rem(16px) arial;
            margin-top: 6px;
            line-height: 1.4;
            color: #1F3347;

            a {
                color: #1F3347;
            }
        }

        .status {
            justify-content: space-between;
            margin-top: 12px;
            color: #56738F;

            .time {
                margin-right: 8px;
            }

            a {
                color: #56738F;
                font-size: 14px;
            }
        }

        .status_ads {
            align-items: center;
            margin-top: 10px;

            .text_ads {
                background: #FFD966;
                border-radius: 2px;
                color: #664D00;
                line-height: 24px;
                padding: 0 5px;
                margin-right: 8px;
            }

            a {
                color: #56738F;
                font-size: 14px;
            }
        }
    }

    &.inner_section {
        padding: 0 12px;
        margin-bottom: 24px;
        float: left;
        width: 100%;

        &:after {
            content: '';
            position: absolute;
            bottom: -12px;
            left: 12px;
            right: 12px;
            height: 1px;
            background: #D8E1EB;
        }

        .thumb_art {
            border-radius: 4px;

            .icon_thumb {
                bottom: 0;
                left: 0;
            }
        }

        .content {
            padding: 0;

            .title_news {
                font-size: 16px;
            }
        }
    }

    &:last-of-type {
        &:after {
            display: none;
        }
    }

    &.last {
        margin-bottom: 20px;

        &:after {
            display: none;
        }
    }

    &.off-thumb {
        .thumb_ar {
            display: none
        }

        .content {
            border-radius: 4px;
        }
    }
}

.banner_ads {
    overflow: hidden;
}

.no-images {
    font-size: px_to_rem(0px);
    float: left;
    width: 100%;
    border-top: 4px solid #eee;
    border-bottom: 8px solid #eee;
    padding: 20px 0;

    .art_item {
        width: 50%;
        margin: 0;
        display: inline-block;
        float: none;

        .content {
            margin-top: 0;
        }

        &:nth-child(1) {
            padding-right: 20px;

            &:before {
                content: "";
                background: #eaeaea;
                position: absolute;
                right: 0px;
                top: 0;
                bottom: 0;
                width: 1px;
            }
        }

        &:nth-child(2) {
            padding-left: 20px;
        }
    }
}

.box_slide_home {
    position: relative;

    .swiper-control {
        position: absolute;
        top: 12px;
        right: 15px;
        display: flex;
    }

    .swiper-button-prev,
    .swiper-button-next {
        position: static;
        color: #56738F
    }
}

.box_kd {
    .art_item__top {
        margin-bottom: 0;
        background: #fff;
        padding-bottom: 12px;

        &::before {
            content: "";
            background: var(--border);
            height: 1px;
            position: absolute;
            bottom: 12px;
            left: 12px;
            right: 12px;
        }
    }

    .tai-tro {
        color: #56738F;
        justify-content: center;
        align-items: center;
        height: 48px;
        width: 100%;
        margin-top: -12px;

        span {
            margin-right: 12px;
        }
    }
}

#slide_store {
    .bg-white {
        padding: 0 12px 12px 12px;
    }

    .title_box_category {
        margin-left: -12px;
        margin-right: -12px;
    }

    .art_item {
        margin-bottom: 0;

        .thumb_art {
            width: 100%;
            float: none;
            margin: 0;
        }

        .content {
            padding: 12px 0 0 0;

            .title_news {
                font-size: 16px;
                margin-bottom: 8px;
            }
        }
    }
}

.list_news {
    display: flex;
    flex-wrap: wrap;

    .art_item {
        float: left;
        width: 100%;
        background: #fff;
        border-bottom: 1px solid #D8E1EB;
        padding-bottom: 12px;

        .thumb_art {
            width: 125px;
            -webkit-border-radius: 4px;
            border-radius: 4px;
            margin: 0 8px 0 0;
            overflow: hidden;

            video {
                -webkit-border-radius: 4px;
                border-radius: 4px;
                overflow: hidden;
            }

            .icon_thumb {
                bottom: 0;
                left: 0;
            }
        }

        .content {
            padding: 0;
        }

        .title_news {
            font-size: 16px;
        }

        &:last-child {
            margin-bottom: 0;
            border-bottom: 0
        }
    }
}

.art_topstory {
    .thumb_art {
        width: 100%;
        float: none;

        .timer {
            left: 12px;
            bottom: 8px
        }
    }

    .content {
        padding: 12px 12px 16px 12px;
        border-radius: 0;

        .title_news {
            font: 500 px_to_rem(22px) var(--font-title);
            line-height: 1.3;
        }
    }

    &.thumb_big {
        margin-bottom: 0;

        &:before {
            display: none;
        }
    }
}

.grid {
    margin-left: -12px;
    font-size: px_to_rem(0px);
    margin-bottom: 12px;

    .art_item {
        padding-left: 12px;
        float: none;
        display: inline-block;
        vertical-align: top;

        .thumb_art {
            width: 100%;
            float: none;
        }

        .content {
            width: 100%;
        }
    }

    &_2 {
        .art_item {
            width: 50%
        }
    }

    &_3 {
        .art_item {
            width: 33.3%
        }
    }

    &_4 {
        .art_item {
            width: 25%
        }
    }

    &_5 {
        .art_item {
            width: 20%
        }
    }
}

.article_small {
    margin-left: -28px;
    font-size: px_to_rem(0px);
    position: relative;
    margin-bottom: 28px;

    &:before {
        content: "";
        background: #eaeaea;
        height: 1px;
        position: absolute;
        left: 28px;
        right: 0;
        bottom: 0px;
    }

    .art_item {
        width: 33.3%;
        padding-left: 28px;
        float: none;
        display: inline-block;
        vertical-align: top;

        .thumb_art {
            width: 94px;
            margin-right: 12px;
        }

        .content {
            overflow: hidden;
            margin-top: 0;

            .title_news {
                font: 500 px_to_rem(16px) var(--font-title)
            }
        }
    }
}

.block_tag {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .txt_tag {
        color: #56738F;
        font-weight: 400;
        font-size: 14px;
        width: 34px;
        margin-top: 5px;
    }

    .list_tag {
        width: calc(100% - 34px);
        padding-left: 12px;

        h3 {
            font-size: 14px;
            font-weight: 400;
            display: inline-block;
            position: relative;
            line-height: 30px;
            padding-right: 28px;

            &::before {
                content: "";
                background: #D8E1EB;
                width: 1px;
                height: 12px;
                position: absolute;
                right: 10px;
                top: 10px;
                transform: rotate(25deg);

            }

            a {
                color: #1F3347;
            }

            &:last-child {
                &::before {
                    display: none;
                }
            }
        }
    }
}

.download_app {
    position: relative;
    margin-bottom: 24px;
    border: 1px solid #D8E1EB;
    padding: 12px;
    border-radius: 4px;
    color: #1F3347;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    span {
        width: calc(100% - 75px);
        padding-right: 10px;
    }

    .down {
        width: 75px;
        text-align: center;
        color: #56738F;
        background: #D8E1EB;
        border-radius: 2px;
        margin-bottom: 20px;
        padding: 10.5px 5px;
        margin: 0;
        height: 40px;
    }
}

.circle_s {
    position: relative;
}

.tip {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    height: 24px;
    line-height: 24px;
    background: rgb(23, 25, 26);
    white-space: nowrap;
    padding: 0px 10px;
    border-radius: 3px;
    display: none;
    color: rgb(255, 255, 255) !important;
    font-size: 12px !important;
    text-decoration: none !important;
}

// LOCATION-STAMP
.location-stamp {
    color: #4f4f4f;
    font-size: 14px;
    letter-spacing: -0.5px;
    text-transform: uppercase;
    margin-right: 15px;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        bottom: 5px;
        width: 8px;
        height: 1px;
        background: #4f4f4f;
        right: -10px;
    }
}

.short_intro .location-stamp {
    &:before {
        bottom: 5px;
    }
}