@import "var.scss";
@charset "utf-8";

* {
  margin: 0;
  padding: 0;
  box-sizing:border-box;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
  -ms-box-sizing:border-box;
}
/*html{overflow-x: hidden;}*/
/**My vne**/

#myvne_taskbar{position: absolute; height: 44px; z-index: 99; right: 5px; top: 0;
        .icon-arrow {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 14px;
            height: 7px;
            right: 0;
            margin: auto;
            display: none;
            &:before {
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-bottom: 5px solid #fff;
              content: "";
              bottom: 0;
              left: 2px;
              position: absolute;
              z-index: 2;
            }
            &:after {
              width: 0;
              height: 0;
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-bottom: 7px solid #eee;
              content: "";
              left: 0;
              bottom: 0;
              position: absolute;
            }
          }
        ul.myvne_form_log{
            li.myvne_user{
                float: right;
                height: 44px;
                margin: 0;
                line-height: 44px;
                position: relative;
                a.myvne_icon{display: block; height: 44px; line-height: 44px !important; text-align: center;
                    padding:0 10px; 
                    &.myvne_avatar{
                        
                        img{width: 18px; height: 18px; line-height: 44px; display: inline-block; vertical-align: middle; border-radius: 50%;-webkit-border-radius: 50%;-moz-border-radius: 50%;-ms-border-radius: 50%;}
                        .av_no_img{
                            font-size: 14px; font-weight: 700; color: #fff; background: #BDBDBD; width: 18px; height: 18px; display: inline-block; text-align: center; line-height: 18px; border-radius: 50%;-webkit-border-radius: 50%;-moz-border-radius: 50%;-ms-border-radius: 50%;
                        }
                    }
                }
                &.active{
                    .ic-user{background-position: -54px -193px;}
                    .ic-bell{background-position: -270px -192px;}
                    .icon-arrow{display: block;}
                }
            }
        }
    .badged {
        position: absolute;
        font-size: 9px !important;
        padding: 2px 3px 1px 3px;
        line-height: 1 !important;
        background-color: #004f8b;
        color: #fff;
        top: 20px;
        left: 0;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
    }
    .input_form{color: #333;}
    
    .block_sub_myvne{width: 100vw; min-width: 320px; max-width: 590px; position: absolute; background: #fff; max-height: 100vh; top: 44px; right: -5px; line-height: initial; display: none;scroll-behavior: smooth;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 9999;
        &.block_sub_log{padding: 15px;}
        -webkit-box-shadow: -2px 3px 5px 0px rgba(153,153,153,0.7);
-moz-box-shadow: -2px 3px 5px 0px rgba(153,153,153,0.7);
box-shadow: -2px 3px 5px 0px rgba(153,153,153,0.7);
    }
    .close-lb{width: 40px; height: 40px; text-align: center; line-height: 40px; position: absolute; top: 5px; right: 4px; cursor: pointer;}
    .header_sub_myvne{
        margin-bottom: 10px;
        border-bottom: 1px solid #eee;
        .m_page_title{margin-bottom: 10px;
            h2{font-size: 20px !important; line-height: 25px !important; padding-right: 40px;}
        }
        .pre-text {
            padding: 0 0 15px;
            position: relative;
            font-size: 14px;
            line-height: 20px;
            color: #666;
                a{
                    color: #004f8b;
                    text-decoration: underline;
                    &.edit_profile{color: #999999; text-decoration: none; display: inline-block; margin-left: 20px; font-size: inherit !important; line-height: inherit !important;}
                }
        }
    }
    .li_pass{
        position: relative;
        .input_form{padding-right: 70px;}
    }
    .showpass-btn {
        position: absolute;
        right: 12px;
        top: 25px;
        color: #004f8b;
        display: block; font-size: 14px; padding: 10px 0;
    }
    .check_form{
        input{float: left; margin-right: 5px;}
        label{float: left;
            a{text-decoration: underline; color: #666;}
        }
        color: #666; font-size: 14px;
        .forget_pass{font-size: 14px; color: #666;}
    }
    .btn_vne{width: 100%; text-align: center;}
    .or_text{
        position: relative;
        text-align: center;
        margin-bottom: 20px;
        &::before{
            content: "";
            height: 1px;
            width: 100%;
            background-color: #eee;
            position: absolute;
            top: 10px;
            left: 0;
        }
        h4{
                font-size: 12px;
                text-transform: uppercase;
                padding: 0 23px;
                background-color: #fff;
                display: inline-block;
                margin: 0 auto;
                z-index: 1;
                position: relative;
        }
    }
    .social_login{
        a{display: block; position: relative; width: 47%; padding: 10px; font-size: 16px;
            &.face_login{border: 1px solid #3b589c;}
            &.google_login{border: 1px solid #da5542;}
            .ic{position: absolute; top: 10px; right: 10px;}
            .ic-facebook{
                width: 17px;height: 18px; background-position: -126px -192px;
            }
            .ic-google-plus{
                width: 18px;height: 18px; background-position: -144px -192px;
            }
        }
    }
    .copyright_myvne{text-align: center; color: #999; font-size: 14px; padding-top: 15px; border-top: 1px solid #e7e7e7; margin-top: 30px;}
    ul.list-items{font-size: 18px;}
        ul.list-items li {
            padding-left: 20px;
            width: 100%; float: left;
            line-height: 22px;
            position: relative;
            &::before{
                width: 6px;height: 6px;background: #ccc; content: ""; position: absolute; top: 7px; left: 0;
            }
        }
     ul.list-items li:not(:last-child) {
        padding-bottom: 30px;
        }
    .download_pdf{display: inline-block;}
    
    .block_sub_loged{
        .header_sub_myvne{padding: 15px;}
    }
    .menu_profile{ border-bottom: 1px solid #ccc;
        li{width: 50%; padding-right: 15px; float: left;
            &:nth-child(even){padding-right: 0;}
            a{display: block; padding: 12px 0; font-size: 18px !important; line-height: inherit !important; border-bottom: 1px solid #e7e7e7;}
            &:last-child,&:nth-last-child(2){
             a{border: none;}   
            }
        }
        &.other_link{border-bottom: none;
            li{
                a{border: none; color: #999999; font-size: 16px !important; line-height: inherit !important; }
            }
        }
    }
    .loged{
        .header_sub_myvne{margin-bottom: 0;}
    }
    .box_saved_news{background: #f7f7f7; float: left; margin: 0 -15px 0 -15px; padding: 0 15px;
        
    }
    .head_box{border-bottom: 1px solid #e7e7e7; font-size: 16px; padding: 10px 0;
            a{color: #999;}
        }
    .list_news_saved{
        li{width: 100%; float: left;
            a{display: block; padding: 10px 0; font-size: 18px; border-bottom: 1px solid #e7e7e7; 
                span{display: block; color: #999; font-size: 14px; margin-top: 10px;}
            }
        &.read_pass{
                a{color: #aaa;}
            }
        }
    }
    .logout_link{display: block; width: 100%; float: left; text-align: center; padding: 15px 0; font-size: 14px !important; line-height: inherit !important; color: #999;}
    ul.myvne_form_log li.notify_myvne{
        .block_sub_myvne{right: -45px;}
        .head_box{padding-top: 0; border: none;}
    }
    .view_more_myvne{display: block; text-align: center; font-size: 16px; color: #999; padding: 10px 0;}
}
#myvne_taskbar{
    .loged{
        .block_sub_myvne{max-height: 80vh;}
    }
}
#open_login{
    @extend #myvne_taskbar;
    position: relative; height: auto; z-index: inherit; right: inherit; top: inherit;
    .block_sub_myvne{display: block; top: inherit; right: inherit; min-width: inherit; max-height: inherit; max-width: 100%;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
    }
}
.fancybox-close{display: none;}
.myvne_thumb{
    float: left;
    margin-right: 10px;
}

/**CSS V2**/
#myvne_taskbar{
    &.myvne_taskbar_v2{
      
      .myvne_user_status{
        li{
          width: 100%;float: left;
          
          &.li_bd{
            border-bottom: 1px solid #E5E5E5;
            padding: 0 0 10px 0;
            margin-bottom: 10px;
          }
          &.li_account{
            display: flex;
            align-items: center;
            padding: 0 0 15px 0;
          }
          a{
            font-size: 15px;
            color: #4F4F4F;
            padding: 10px 0;
            position: relative;
            width: 100%;
            display: block;
            .number_sub{opacity: 0.4;}
          }
          &:last-child{
            a{}
          }
        }
  
        .avatar_sub {
              width: 48px;
              height: 48px;
              background: #E5E5E5;
              text-align: center;
              line-height: 48px;
              font-size: 20px;
              color: #fff;
              overflow: hidden;
              border-radius: 50%;
              font-weight: 700;
              text-transform: uppercase;
              img{width: 48px;height: 48px;}
          }
        .name_sub{
          width: calc(100% - 48px);
          padding-left: 10px;
          color: #4F4F4F;
          font-size: 16px;
          font-weight: 700;
        }  
      }
    }
  }