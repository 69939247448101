@import "myvne-2017.scss";

// left menu 
.humberger-btn {
    .icon-ns:last-child {
        display: none;
    }

    &.active {
        .icon-ns:first-child {
            display: none;
        }

        .icon-ns:last-child {
            display: block;
        }
    }

    .sticky:not(.header-top--ver2) & {
        position: absolute;
        width: 48px;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F6F7FB;
        left: -52px;
        padding: 0 12px;

        svg {
            width: 20px;
            height: 20px;
            color: #7A99B8;
        }
    }

    .sticky--active:not(.header-top--ver2) & {
        left: 0;
        transition: all .3s ease-in;
    }
}

body.fixed {
    overflow: hidden;
}

.left-nav {
    position: fixed;
    top: 38px;
    height: calc(100% - 48px);
    overflow-y: scroll;
    max-width: 540px;
    width: 100%;
    padding: 16px 12px;
    font-size: 16px;
    border-radius: 0px 0px 20px 20px;
    max-height: 100%;
    z-index: 1042;
    background: #fff;
    display: none;

    &.active {
        display: block;
        border-top: 1px solid #D8E1EB;
    }

    .search-form {
        padding: 0;
        margin-bottom: 24px;
    }

    .list_index {
        border-bottom: 1px solid #D8E1EB;
        align-items: center;
        padding: 6px 0;

        li {
            width: 50%;
            font-weight: bold;

            a {
                color: #1F3347;
            }
        }

        .socail {
            a {
                display: inline-block;
                background: #EEF2F6;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                margin-left: 10px;

                svg {
                    color: #7A99B8
                }
            }
        }
    }

    .footer_nav {
        margin-left: -8px;
        margin-right: -8px;
        margin-bottom: 10px;
        width: calc(100% + 16px);

        ul {
            width: 100%;
            float: left;

        }

        &::before {
            display: none;
        }

        li {
            width: 50%;
            float: left;
            padding: 0 8px;


            &>a {
                font-weight: bold;
                border-bottom: 1px solid #D8E1EB;
                padding: 14px 0;
                float: left;
                width: 100%;
            }

            .sub_menu {
                padding-left: 20px;
                float: left;
                width: 100%;
                border-bottom: 1px solid #F7F7F7;

                a {
                    position: relative;
                    padding: 6px 0 6px 10px;
                    width: 100%;
                    float: left;

                    &::before {
                        content: "";
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background: #B5CCE3;
                        position: absolute;
                        left: 0;
                        top: 14px;
                    }

                    &.active {
                        color: #0B84DA;

                        &::before {
                            background: #0B84DA;
                        }
                    }
                }
            }

            &.active {
                &>a {
                    color: #0B84DA;
                }
            }
        }
    }

    .copyright {
        margin-bottom: 20px;
    }
}

.wrap-main-nav {
    overflow: hidden;

    .main-nav {
        max-width: 100%;
        display: flex;
        background: #F6F7FB;

        .home-item {
            padding-left: 12px;
            padding-right: 8px;
        }

        &__menu {
            overflow-x: scroll;
            @include hide-scrollbar;
        }

        .parent {
            white-space: nowrap;

            li {
                display: inline-block;

                h1,
                h2 {
                    font-size: 14px;
                }

                a {
                    line-height: 40px;
                    display: inline-block;
                    color: #56738F;
                    padding: 0 10px;
                }

                &.home-item {
                    display: inline-flex;
                    align-items: center;

                    a {
                        background: #EDEEF4;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        padding: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    &.active {
                        a {
                            svg {
                                color: #7043A9;
                            }
                        }
                    }
                }

                &.active {
                    a {
                        color: #0B84DA;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .search-btn {
        display: inline-block;
        cursor: pointer;
        height: 19px;
        position: relative;

        input {
            display: none;
        }

        button {
            border: 0;
            background: none;
        }

        .icon-ns {
            position: relative;
            display: block;
            top: 7px;
            -webkit-transition-duration: 400ms;
            transition-duration: 400ms;
            -webkit-transition-property: all;
            transition-property: all;
            -webkit-transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
            transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
        }

        .close {
            width: 11px;
            height: 11px;
            opacity: 0;
        }

        &.is-clicked {
            .icon-ns {
                top: -13px;
                opacity: 0;
            }

            .close {
                opacity: 1;
                top: -6px;
            }
        }
    }

    #myvne_taskbar {
        display: inline-block;
        margin-left: 25px;
    }

    &.sticky {
        z-index: 99;

        &--active {
            z-index: 1040;
            box-shadow: 0 2px 8px rgba(0, 0, 0, .1);

            .home-item {
                padding-left: 50px;
                transition: all .2s ease-in;
            }

            .humberger-btn {
                left: 0;
                margin-top: 0;
            }
        }
    }

    &.main-nav-folder {
        .main-nav {
            .parent {
                li {
                    &:first-child {
                        a {
                            font-weight: bold;
                        }
                    }

                    &.arrows {
                        position: relative;
                        padding-right: 15px;

                        &::before {
                            content: "";
                            border-top: 2px solid #94B3D1;
                            border-right: 2px solid #94B3D1;
                            width: 6px;
                            height: 6px;
                            position: absolute;
                            right: 2px;
                            top: -8px;
                            transform: rotate(45deg);
                        }
                    }

                    &.first {
                        padding-right: 0;

                        &::before {
                            display: none
                        }
                    }
                }
            }
        }
    }

}

.home-item {
    display: inline-flex;
    align-items: center;

    a {
        background: #94B3D1;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            color: #fff;
            width: 12px;
            height: 12px;
        }
    }
}

.right-head {
    margin-right: -10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.logo-be {
    width: 72px;
    padding-left: 10px;
    position: relative;

    &::before {
        content: '';
        height: 22px;
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        border-left: 1px solid rgba(0, 0, 0, 0.2);
    }
}

.search-btn {
    width: 36px;
    height: 48px;
    right: 38px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-ns {
        width: 18px !important;
        height: 18px !important;

        &:last-child {
            display: none;
        }
    }

    &.is-clicked {
        .icon-ns {
            &:last-child {
                display: block;
            }

            &:first-child {
                display: none;
            }
        }
    }
}

.humberger-btn.active {
    margin-top: -10px;
}

.humberger-btn.active~.logo {
    width: 80px;
    margin-top: -9px;
}

.humberger-btn.active~.right-head {

    .logo-be,
    .search-btn {
        opacity: 0;
    }
}

.search-wrap {
    padding: 10px 0;
    background: #fff;
    display: none;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, .1);
    position: absolute;
    width: 100%;
    z-index: 991;

    input {
        font-size: 18px;
        height: 45px;
        width: 100%;
        outline: 0;
    }
}

.header-top {
    z-index: 98;
    height: 48px;
    background: #fff;
    border-bottom: 1px solid var(--border);

    .container {

        &::before,
        &::after {
            display: none;
        }
    }

    .flex {
        height: 48px;
        align-items: center;
        justify-content: space-between;
    }

    .icon-ns {
        width: 20px;
        height: 20px;
        color: #7A99B8
    }

    .logo {
        position: absolute;
        left: 50%;
        width: 97px;
        margin-left: -48px;
    }
}

#myvne_taskbar,
#open_login,
#open_login {
    top: -1px;
    position: relative;
}

#myvne_taskbar {
    svg.ic {
        fill: #7A99B8;
        width: 18px;
        height: 18px;
    }
}

#myvne_taskbar .block_sub_myvne,
#open_login .block_sub_myvne,
#open_login .block_sub_myvne {
    right: -7px;
    top: 87px;
}

// @media (max-width: 340px) {
//     .header-top {
//         .logo {
//             margin-left: 15px;
//             width: 90px;
//         }
//     }
// }