.block_share_cmt_fb {
  z-index: 99 !important;
}

.overlay {
  position: fixed;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 97;
  height: calc(100% - 48px);
  width: 100%;
  display: none;

  &.active {
    display: block;
  }
}

.mfp-close {
  right: 0px;
  top: 0;
}

.col-left-res.vote_rating_box .count-vote {
  position: relative;
}

.ic-user {
  background: url(https://s1.vnecdn.net/ione/restruct/c/v50/ione/mobile/images/graphics/icon_vne.svg) no-repeat;
  display: inline-block;
  vertical-align: middle;
  background-position: -611px -193px;
  width: 18px;
  height: 18px;
}

.ic-bell {
  background: url(https://s1.vnecdn.net/ione/restruct/c/v50/ione/mobile/images/graphics/icon_vne.svg) no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  background-position: -252px -192px;
}

.ad_time_txt {
  z-index: 1040 !important;
}

.error_mess {
  font: 400 14px arial;
  color: #9f224e;
  padding-top: 5px;
}

.lds-loading {
  text-align: center;
  color: #c4c4c4;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 10px;
  background: #c4c4c4;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }

  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

// style 2

.pixel-spinner,
.pixel-spinner * {
  box-sizing: border-box;
}

.pixel-spinner {
  height: 70px;
  width: 70px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pixel-spinner .pixel-spinner-inner {
  width: calc(70px / 7);
  height: calc(70px / 7);
  background-color: #c4c4c4;
  color: #c4c4c4;
  box-shadow: 15px 15px 0 0, -15px -15px 0 0, 15px -15px 0 0, -15px 15px 0 0, 0 15px 0 0, 15px 0 0 0, -15px 0 0 0, 0 -15px 0 0;
  animation: pixel-spinner-animation 2000ms linear infinite;
}

@keyframes pixel-spinner-animation {
  50% {
    box-shadow: 20px 20px 0px 0px, -20px -20px 0px 0px, 20px -20px 0px 0px, -20px 20px 0px 0px, 0px 10px 0px 0px, 10px 0px 0px 0px, -10px 0px 0px 0px, 0px -10px 0px 0px;
  }

  75% {
    box-shadow: 20px 20px 0px 0px, -20px -20px 0px 0px, 20px -20px 0px 0px, -20px 20px 0px 0px, 0px 10px 0px 0px, 10px 0px 0px 0px, -10px 0px 0px 0px, 0px -10px 0px 0px;
  }

  100% {
    transform: rotate(360deg);
  }
}


.animated {
  animation-duration: .5s;
  animation-fill-mode: both;
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}



@keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0)
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0)
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp
}