.form_box {
  .title_box_category {
    margin: 0;
  }
}

.form_element {
  font: 400 px_to_rem(16px) arial;

  li {
    margin: 0 0 16px 0;
    position: relative;

    &:last-child {
      margin: 0;
    }
  }

  .btn_vne {
    background: #0963C8 !important;
    border-radius: 2px;
    padding: 12px 25px;
    font-size: 14px;
    font-weight: 400;
  }

  .txt_clear_all {
    background: none;
    color: #999 !important;
    padding: 12px;
  }
}

.require_txt {
  font-size: 14px;
  color: #1F3347;
  border-top: 1px solid #D8E1EB;
  padding-top: 15px;
  margin-top: 15px;
}

.label_form {
  width: 100%;
  float: left;
  padding: 0 0 5px 0;
}

.input_form {
  width: 100%;
  border: 1px solid #D8E1EB;
  border-radius: 3px;
  color: #56738F;
  font: 400 16px arial;
  height: 40px;
  padding: 2px 10px;
  background: #fff;
  outline: none;
  transition: 0.3s ease;

  &:focus {
    color: #333;
    border: 1px solid #0B84DA;
  }
}

.input_area {
  height: 100px;
}

input.input_form,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.txt_error {
  font: 400 14px arial;
  color: #ff0000;
  padding-top: 5px;
}

.txt_note_form {
  text-align: right;
  font-size: 14px;
  color: #666;
  padding-top: 5px;
}

.label_form {
  .txt_error {
    padding-left: 5px;
    padding-top: 0;
    color: #0963C8
  }
}

.input_70 {
  width: 69%;
}

.input_30 {
  width: 29%;
}

.input_50 {
  width: 49%;
}

.capcha {
  float: left;
  margin: 0 10px;
  height: 40px;
  line-height: 40px;

  img {
    display: inline-block;
    vertical-align: middle;
    height: 38px !important;
  }
}

.btn_refrest {
  width: 27px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  display: inline-block;

  img {
    margin-top: 5px;
  }
}

.txt_clear_all {
  background: none;
  padding: 5px 8px;
  text-decoration: underline;
  color: #333 !important;
}

.trumbowyg-box,
.trumbowyg-editor {
  float: left;
  margin: 0 auto !important;
}

.login_title {
  font-size: px_to_rem(14px);
  font-weight: 700;
}

.p_social {
  text-align: center;

  a {
    display: inline-block;
    margin: 0 5px;
    width: 120px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;

    .ic {
      /*color: #fff;
            float: left;
            height: 30px;
            width: 30px;*/
      float: left;
      margin: 7px 0 0 8px;
      line-height: 30px;
      position: relative;

      &:after {
        content: "";
        width: 1px;
        height: 30px;
        position: absolute;
        top: -7px;
        left: 18px;
        background: rgba(255, 255, 255, 0.27);
      }
    }

    &.facebook {
      background: #3B589C;
    }

    &.google {
      background: #DA4835;
    }
  }
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1046;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1047;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;

  button.mfp-close {
    display: inline-block !important
  }
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: none !important;
  outline: none;
  z-index: 1046;
  touch-action: manipulation;
  font-size: 25px;
  position: absolute;
  right: 10px;
  top: 5px;
  color: #666;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}


.mfp-iframe-holder {
  /*padding-top: 40px;
  padding-bottom: 40px;*/
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  font-size: 42px;
  top: 0;
}

.mfp-iframe-scaler {
  width: 100%;
  min-height: 100vh;

}

.mfp-iframe-holder {
  padding: 0;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: none;
  background: #fff;

}

.white-popup {
  position: relative;
  background: #f4f4f4;
  width: auto;
  max-width: 500px;
  margin: 0px auto !important;
  overflow: hidden;

  p.center {
    text-align: center;
  }

}

.mfp-with-zoom.mfp-ready .white-popup {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.mfp-with-zoom .white-popup {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

/* animate out */
.mfp-with-zoom.mfp-removing .white-popup {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}

/* Dark overlay, start state */
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

/* animate in */
.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* animate out */
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.title_popup {
  margin: -5px -15px 15px -15px;
  padding: 0px 15px 10px 15px;
  position: relative;
  font: bold 1.5rem var(--font-title);
  width: calc(100% + 30px);

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-image: -webkit-gradient(linear, left top, right top, from(#16d1f2), to(#9dfeaf));
    background-image: -webkit-linear-gradient(left, #0799ff, #9dfeaf);
    background-image: -o-linear-gradient(left, #0799ff, #9dfeaf);
  }
}

.content-popup {
  .title_box_category {
    &::before {
      display: none
    }
  }
}

.content_poup {
  padding: 15px;
  background: #fff !important;
  font: 400 px_to_rem(16px) arial;

  &.popup_padding10 {
    padding: 10px;
  }

  .title_new {
    margin: -5px -15px 15px -15px;
    padding: 0px 15px 10px 15px;
    position: relative;
    font: bold px_to_rem(24px) var(--font-title);

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-image: -moz-linear-gradient(left, #0799ff #9dfeaf);
      background-image: -webkit-gradient(linear, left top, right top, from(#16d1f2), to(#9dfeaf));
      background-image: -webkit-linear-gradient(left, #0799ff, #9dfeaf);
      background-image: -o-linear-gradient(left, #0799ff, #9dfeaf);
    }
  }

  p {
    line-height: 1.4;
  }
}

.btn_vne {
  background: #178fd6;
  color: #fff;
  font: 700 .8125rem arial;
  padding: 10px 25px;
  border: none;
  cursor: pointer;
  margin: 0;

  &.btn_normal {
    font-weight: 400;
  }

  svg.ic {
    margin-right: 5px;
    vertical-align: text-top;
  }

  &.btn_full {
    width: 100%;
    text-align: center;
  }

  &.btn_disable {
    background: #F7F7F7;
    color: #BDBDBD !important;
  }

  &.btn_restart {
    color: #616161 !important;
  }

  &.btn_grey {
    background: #E5E5E5;
    color: #222;
  }

  &.btn_blue {
    background: #076DB6;
    color: #fff;
  }

  &.btn_view_cancel {
    background: none;
    color: #757575;
    text-decoration: underline;
    padding: 0 15px;

    &:hover {
      color: #087CCE;
    }
  }
}

.no_title {
  background: #fff;

  .content_poup {
    background: #fff;
  }

  .mfp-close {
    display: none;
  }
}

.text_box_lb {
  padding: 15px;
  background: #fff;
  font: 700 px_to_rem(18px) arial;
  line-height: $lh;

  p {
    margin: 5px 0;
  }
}

.close_pop {
  text-align: center;
  padding-top: 15px;
  border-top: 1px solid #e7e7e7;
  margin-bottom: 15px;

  .mfp-close {
    background: var(--blue);
    display: inline-block;
    position: static;
    width: auto;
    height: 40px;
    opacity: 1;
    ;
    font-size: 14px;
    color: #fff;
    padding: 0px 30px;
    border-radius: 4px;
  }
}

/*Lightbox Đăng nhập*/

/*End Lightbox Đăng nhập*/
/**VOTE RESULT**/


/**RATING RESULT**/
.rate-popup {

  .box_rating {
    width: 100% !important;
    float: left;
    margin: 0 !important;
    padding: 15px !important;

    .btn_vne {
      width: 140px;
      font-size: 17px;
    }
  }

  .box_rating {
    padding: 0 30px;
    text-align: center;
    background: none;
    border: none;
  }

  .rating {
    justify-content: center;
    margin-bottom: 25px;
    width: 100%;
  }
}

// .content_border_top {
//   border-top: 1px solid #e7e7e7;
// }

// .list_rating_res {
//   background: #f7f7f7;
//   border-top: 1px solid #e7e7e7;
//   border-left: 1px solid #e7e7e7;
//   border-right: 1px solid #e7e7e7;

//   .row_rating {
//     height: 50px;
//     width: 100%;
//     border-bottom: 1px solid #e7e7e7;
//     position: relative;
//     line-height: 50px;
//     font-size: px_to_rem(16px);
//     padding-left: 50px;

//     .number_rating_pt {
//       width: 50px;
//       height: 49px;
//       color: #fff;
//       text-align: center;
//       line-height: 50px;
//       background: #c94171;
//       position: absolute;
//       top: 0;
//       left: 0;
//     }

//     .number_voted {
//       height: 50px;
//       line-height: 50px;
//       position: absolute;
//       top: 0;
//       left: 65px;
//     }

//     .percent_rating {
//       float: left;
//       background: #fbd5e2;
//       height: 49px;
//     }
//   }
// }

// .social_lightbox li {
//   display: inline-block;
//   margin: 5px 1%;
//   width: 46%;
//   line-height: 26px;
// }

// .note_voted {
//   font-size: px_to_rem(16px);
//   color: var(--blue);
//   padding-top: 15px;
//   margin-top: 15px;
//   border-top: 1px solid #e7e7e7;
//   margin-bottom: 15px;
// }

// .outer_page {
//   .total_vote {
//     margin-top: 20px;
//   }

//   .list_rating_res {
//     border-top: none;
//   }
// }
/**Upload file**/
.form_element {
  #thumbnails {
    li {
      width: 30%;
      float: left;
      height: 90px;
      margin: 5px 5px 10px 0;
      overflow: hidden;

      .wrap {
        height: 70px;
        overflow: hidden;
      }

      .del_action {
        line-height: 18px;
        float: right;
      }
    }

  }
}

/*Lightbox model-rating*/
#model-rating-popup {
  .title_popup {
    font: 700 22px arial;
    color: #222222;
    padding: 18px 20px;
  }

  .content_poup {
    background: #fff;
    padding: 0px 5px 20px 20px;

    p {
      margin-bottom: 15px;
      font-size: 18px;
      line-height: 24px;
    }
  }

  .scrollbar-inner {
    max-height: 350px;
    padding-right: 15px;
  }
}

// CUSTOM POPUP NGÔI SAO
.white-popup {
  background: #fff !important;
}

// .mfp-content {
//   .width_common {
//     float: none;
//   }
// }

.lightbox_dangnhap,
.lightbox_vipham {
  padding: 15px;

  .content_poup {
    padding: 0
  }
}

/*Lightbox Đăng nhập*/
.lightbox_dangnhap {
  .form_element {
    width: 100%;
  }

  .login_title {
    font: 700 14px arial;
    color: #000;
    margin-bottom: 10px;
  }

  .login-left {
    width: 100%;
    float: left;
  }

  .login-right {
    width: 100%;
    float: left;

    .login-txt {
      input {
        height: 35px;
        background: #fff;
        width: 100%;
        font-size: 14px;
        padding: 0 10px;
        margin-bottom: 10px;
        border: 1px solid #eaeaea;
      }
    }
  }

  .login-btt {
    text-align: center;
    margin-top: 10px;

    input {
      background: #178fd6;
      font-size: 14px;
      color: #fff;
      padding: 8px 20px;
    }
  }

  .p_social {
    font-size: px_to_rem(14px);
    margin: 20px 0;

    a {
      width: 134px;
      height: 30px;
      line-height: 30px;
      display: inline-block;
      text-align: center;
      color: #fff;
      margin-bottom: 10px;

      i {
        color: #fff;
        float: left;
        height: 30px;
        width: 30px;
        line-height: 30px;
        border-right: 1px solid rgba(255, 255, 255, 0.27)
      }

      .icon-ns {
        color: #fff;
        margin: 0;
        width: 14px;
        height: 14px;
      }
    }

    .facebook {
      background: #3B589C;

    }

    .google {
      background: #DA4835;
    }
  }

  .login-type {
    font: 700 14px arial;
    color: #000;
    margin-bottom: 5px;
  }

}

/*End Lightbox Đăng nhập*/

#popup-sn {
  background: none;
  max-width: 375px;

  .content_poup {
    background: url(images/graphics/popup_sn.png) no-repeat;
    background-size: contain;
    height: 500px;
    position: relative;
    padding: 0;
  }

  .send_comment {
    background: linear-gradient(98.13deg, #C8A44F -0.03%, #F3E4BD 100.75%, #C8A44F 100.76%);
    border-radius: 4px;
    padding: 11px 0;
    line-height: 42px;
    color: #17191A;
    font: 700 16px arial;
    display: inline-block;
    width: 164.32px;
    text-align: center;
    position: absolute;
    top: 385px;
    left: 50%;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    -moz-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
  }

  @media (max-width: 340px) {
    .send_comment {
      top: 355px
    }
  }

  @media (max-width: 320px) {
    .send_comment {
      top: 335px
    }
  }

  .close_pop {
    border-top: 0;

    .mfp-close {
      color: #000;
      font-size: 28px;
      position: absolute;
      padding: 0;
      width: 36px;
      height: 36px;
      line-height: 38px;
      opacity: 1;
      right: 30px;
      top: 70px;
      background: #fff;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
    }
  }

}

#popup-sn1 {
  background: none;
  width: 100%;
  overflow: visible;

  .content_poup {
    background: url(images/graphics/m_bg_quiz1.png) no-repeat;
    background-size: 414px;
    height: 60px;
    position: relative;
    padding: 0;
  }

  .send_comment {
    background: linear-gradient(98.13deg, #C8A44F -0.03%, #F3E4BD 100.75%, #C8A44F 100.76%);
    border-radius: 4px;
    padding: 7px 0;
    line-height: 32px;
    color: #17191A;
    font: 700 14px arial;
    display: block;
    width: 120px;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 15px;
  }

  .close_pop {
    border-top: 0;

    .mfp-close {
      color: #000;
      font-size: 28px;
      position: absolute;
      padding: 0;
      width: 30px;
      height: 30px;
      line-height: 30px;
      opacity: 1;
      right: 5px;
      top: -19px;
      background: #fff;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
    }
  }

  @media (max-width: 360px) {
    .content_poup {
      background-size: 360px;
      height: 55px;
    }
  }

  @media (max-width: 320px) {
    .send_comment {
      width: 110px;
      right: 5px;
      margin-top: 5px;
    }
  }
}

#email-popup,
#email-popup-2 {
  form {
    .form-group {
      margin-bottom: 15px;

      label {
        font: bold px_to_rem(18px) var(--font-title);
        display: block;
        margin-bottom: 10px;
      }

      .input_form {
        float: none;
        border: 1px solid #dfdfdf;
        border-radius: 4px;
      }

      input {
        &.txt-capcha {
          width: 100px;
          float: left;
          margin-top: 4px
        }
      }

      #b_captcha {}

      select {
        width: 100%;
        float: none;
        border: 1px solid #dfdfdf;
        border-radius: 4px;
        color: #333;
        font: 400 1rem arial;
        height: 40px;
        padding: 2px 10px;
        background: #fff;
        outline: none;
      }

      textarea {
        color: #333;
        padding: 10px;
      }

      .checkbox {
        margin: 25px 0;
        position: relative;

        label {
          padding-left: 35px;
          cursor: pointer;
          font-size: 16px;
          font-family: arial;
          font-weight: 400;

          span {
            position: relative;
          }

          svg {
            display: none;
          }

          input {
            top: 0px;
            width: 15px;
            height: 15px;
          }

          input[type="checkbox"] {
            display: none;
          }

          span:before {
            content: "";
            position: absolute;
            width: 25px;
            height: 25px;
            top: -6px;
            left: -35px;
            border: 1px solid #dfdfdf;
            border-radius: 2px;
          }

          input[type="checkbox"]:checked+svg {
            display: inline-block;
            position: absolute;
            top: 1px;
            left: 1px;
            color: #178fd6;
            width: 13px;
            height: 13px;
            margin-left: 6px;
            text-align: center;
          }
        }
      }

      .mfp-close {
        position: static;
        display: inline-block;
        font-size: 14px;
        color: #999999;
        border-bottom: 1px solid #ccc;
        margin-left: 10px;
      }
    }

    .btn_vne {
      border: 1px solid transparent;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(#fff, #fff), linear-gradient(#16d1f2, #0799ff);
      color: #178fd6 !important;
      font-size: 16px;
      font-weight: 400;
      border-radius: 3px;
    }
  }
}

/*FORM GỬI QUÀ TẾT*/
.email-popup-3,
#code-popup {
  clear: both;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  .content_poup {
    background: url(images/graphics/bg_quatet.png) !important;
    padding: 20px;
    background-size: cover !important;
  }

  .top_center {
    text-align: center;
    position: relative;
    padding-bottom: 15px;

    .title_new:before {
      display: none;
    }

    .lead {
      a {
        color: var(--blue)
      }
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-image: -moz-linear-gradient(left, #0799ff #9dfeaf);
      background-image: -webkit-gradient(linear, left top, right top, from(#16d1f2), to(#9dfeaf));
      background-image: -webkit-linear-gradient(left, #0799ff, #9dfeaf);
      background-image: -o-linear-gradient(left, #0799ff, #9dfeaf);
    }
  }

  h3 {
    font-size: 16px;
    margin-top: 15px;
    color: #666;
    margin-bottom: 25px;
  }

  form {
    .form-group {
      margin-bottom: 20px !important;

      label {
        float: left;
        width: 100px;
        font: 400 16px arial !important;
        margin-top: 12px;
        color: #666;
      }

      .input_form {
        overflow: hidden;
        width: 70%;
        width: calc(100% - 100px);
        color: #333;
      }

      .txt_error {
        padding-left: 100px;
        font-size: 14px;
        margin-top: 5px;
        color: #CD201F
      }
    }

    .btn_vne {
      margin-left: 100px;
    }
  }

  button.mfp-close {
    color: #168fd6;
    background: #fff;
    opacity: 1;
    border: 1px solid #168fd6;
    width: 30px;
    height: 30px;
    line-height: 29px;
    font-size: 25px;
    right: 10px;
    top: 10px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
  }
}

#code-popup {
  max-width: 600px;

  .code {
    border: 1px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(#fff, #fff), linear-gradient(#16d1f2, #0799ff);
    color: #178fd6 !important;
    font-size: 24px;
    font-weight: 400;
    border-radius: 3px;
    display: inline-block;
    padding: 9px 18px;
    margin-bottom: 15px;
  }
}

/*form_gopy*/
#form_gopy {
  max-width: 710px;

  .content_poup {
    background: #fff;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.5px;

    .logo {
      width: 130px;
      display: block;
      margin: 0 auto 10px;
    }

    .lead {
      border-bottom: 1px solid #dfdfdf;
      padding-bottom: 10px;
      margin-bottom: 20px;
    }

    h3 {
      color: #333;
      font-size: 16px;
      margin-bottom: 15px;
      line-height: 1.4
    }

    .rating {
      margin: 20px 0;

      p {
        display: inline-block;
      }

      ul {
        display: inline-block;
        margin: 20px 0;

        li {
          display: inline-block;
          margin-right: 15px;

          label {
            input {
              display: none;
            }

            input[type="radio"]:checked+svg {
              color: #F2C94C;
            }

            .icon-ns {
              width: 40px;
              height: 40px;
              color: #E5E5E5;
              margin: 0;
            }
          }

          a {
            .icon-ns {
              color: #E5E5E5;
              width: 40px;
              height: 40px;
              margin: 0 10px;
              vertical-align: text-bottom;
            }

            &.active,
            &:hover {
              .icon-ns {
                color: #F2C94C
              }
            }
          }
        }
      }
    }

    textarea {
      background: #f4f4f4;
      height: 102px;
      border-radius: 0;
      float: none;
      resize: none;
    }

    .bottom_form {
      margin-top: 30px;

      .back {
        color: #999;
        display: inline-block;
        font-size: 14px;

        img {
          margin: 0px 6px 0 0;
          float: left;
        }
      }

      .btn_submit_form {
        color: #fff !important;
        background: #0296F8;
        border: 0;
        padding: 0px 30px;
        line-height: 40px;
        float: right;
        border-radius: 4px;
        margin-top: -10px;
      }
    }
  }

  .close_pop {
    border: 0;
    padding: 0;
    margin: 0;

    button {
      background: none;
      color: #999;
      font-size: 30px !important;
      font-family: -webkit-body;
      position: absolute;
      padding: 0;
    }
  }
}

#notice-popup {
  .title-popup {
    padding: 15px;
    position: relative;
    font: bold 1.5rem var(--font-title);

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-image: -webkit-gradient(linear, left top, right top, from(#16d1f2), to(#9dfeaf));
      background-image: -webkit-linear-gradient(left, #0799ff, #9dfeaf);
      background-image: -o-linear-gradient(left, #0799ff, #9dfeaf);
    }
  }

  .content-popup {
    font: 400 1rem arial;
    padding: 15px;
  }
}

#notice-popup-2 {
  max-width: 500px;

  .content_poup {
    background: #fff;
    letter-spacing: 0.5px;
    text-align: center;
    padding: 30px 20px;
    font-family: 'Roboto', sans-serif;

    .checked {
      background: #0296F8;
      display: block;
      margin: 0 auto 20px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      text-align: center;
      line-height: 60px;
    }

    .lead {
      margin-bottom: 20px;
    }

    h2 {
      text-align: center;
      margin-bottom: 10px;
    }

    .back {
      color: #fff;
      background: #0296F8;
      border: 0;
      padding: 0px 30px;
      line-height: 40px;
      border-radius: 4px;
      display: inline-block;
      margin-bottom: 20px;
    }

    span {
      color: #999;
      font-size: 14px;
      display: block;
    }
  }

  .close_pop {
    border: 0;
    padding: 0;
    margin: 0;

    button {
      background: none;
      color: #999;
      font-size: 30px;
      font-family: -webkit-body;
      position: absolute;
      padding: 0;
    }
  }
}